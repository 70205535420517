$(function () {
	console.log("hello from jquery");

	var $mobileToggle = $(".mobile-toggle");
	var $navItems = $(".nav-items");
	var counter = 1;
	var $heroImage = $(".hero-image");
	$(".sliding-link").click(function(e) {
    e.preventDefault();
    var aid = $(this).attr("href");
    $('html,body').animate({scrollTop: $(aid).offset().top},'slow');
	});


	$mobileToggle.on('click', function () {
		console.log("toggle items");
		$navItems.toggle();
	})
		
	function loopImage() {
		setInterval(function(){
	 		$heroImage.attr('src', '/hero_' + counter + '.jpg');
	 		counter++;
	 		if (counter > 4) {
	 			counter = 1
	 		};
	 		// $('.logo-container').fadeTo( "slow", 0 );
		}, 4000);

	}

	loopImage();
	

	// var $mobileClose = $(".mobile-close");
	// var $mobileOpen = $(".mobile-open");
	// var $mobileSideBar = $(".mobile-sidebar")

	// $mobileClose.click(function () {
	// 	console.log("mobile close clicked");
	// 	$mobileSideBar.hide();
	// });

	// $mobileOpen.click(function () {
	// 	console.log("mobile open clicked");
	// 	$mobileSideBar.show();
	// });
})